<template>
  <b-row>
    <b-col v-if="boardName"
      class="d-flex align-items-center justify-content-start col-md-2 col-12 mb-1"
    >
      <flat-pickr v-model="currentBoardName" class="form-control"
                    :config="{ enableTime: false, dateFormat: 'Y-m-d' }" @input="changeBoardName"/>
    </b-col>
    <b-col v-else
      class="d-flex align-items-center justify-content-start col-md-2 col-12 mb-1"
    >
      <b-button
        style="width: 100%;"
        class="mr-2"
        variant="outline-primary"
        v-b-tooltip.hover.v-primary
        title="Ажил нэмэх"
        @click="
          $emit('update:is-task-handler-sidebar-active', true);
          $emit('close-left-sidebar');
        "
      >
        <feather-icon icon="PlusIcon" /> Ажил нэмэх
      </b-button>
    </b-col>
    
    <b-col
      class="d-flex align-items-center justify-content-start col-md-2 col-12 mb-1"
    >
      <v-select
        v-model="selectedPhone"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        :options="phoneOptions"
        :reduce="(option) => option.name"
        style="width: 100%"
        placeholder="Утасны дугаараар"
        @input="listFilterByPhone"
      />
    </b-col>

    <b-col
      class="d-flex align-items-center justify-content-start col-md-2 col-12 mb-1"
    >
      <v-select
        v-model="selectedUser"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        :options="createdByOptions"
        :reduce="(option) => option.name"
        style="width: 100%"
        placeholder="Хэрэглэгчээр"
        @input="listFilterByCreatedBy"
      />
    </b-col>

    <b-col
      class="d-flex align-items-center justify-content-start col-md-4 col-12 mb-1"
    >
      <b-input-group>
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input
          class="d-inline-block mr-1 form-control"
          placeholder="Ажил хайх"
        />
      </b-input-group>
    </b-col>

    <b-col class="col-md-2 col-12 mb-1">
      <b-button-group class="d-flex align-items-center justify-content-end">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          v-b-tooltip.hover.v-primary
          title="Ажлын самбар"
          @click="gotoUrl('todo-board')"
        >
          <feather-icon icon="TrelloIcon" />
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          v-b-tooltip.hover.v-primary
          title="Ажлын жагсаалт"
          @click="gotoUrl('todo-list')"
        >
          <feather-icon icon="CheckSquareIcon" />
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          v-b-tooltip.hover.v-primary
          title="Өдөр өндөрлөх"
          @click="
            confirmComplete(
              'Нөмөр Кредит ББСБ',
              'Нөмөр асуудал',
              'Нээлттэй хавтас',
              'Нээлттэй самбар'
            )
          "
        >
          <feather-icon icon="BellIcon" />
        </b-button>
      </b-button-group>
    </b-col>
  </b-row>
</template>
  
<script>
import {
  BButtonToolbar,
  BButtonGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  VBTooltip,
  BButton,
  BCardText,
  BRow,
  BCol,
  BCard
} from "bootstrap-vue";
import flatPickr from 'vue-flatpickr-component'
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import Ripple from "vue-ripple-directive";
import { useRouter } from "@core/utils/utils";
import store from "@/store";
import vSelect from "vue-select";
import { ref } from '@vue/composition-api';

export default {
  components: {
    BButtonToolbar,
    BButtonGroup,
    BCardText,
    BButton,
    BCardCode,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormInput,
    BRow,
    BCol,
    vSelect,
    BCard,
    flatPickr
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
   props: {
    boardName: {
      type: String,
      required: true,
    },
    createdByList: {
      type: Array,
      required: true,
    },
    phoneList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {};
  },
  setup(props, { emit }) {
    const currentBoardName = ref(null)
    currentBoardName.value = props.boardName

    const phoneOptions = ref([])
    phoneOptions.value = props.phoneList

    const createdByOptions = ref([]);
    createdByOptions.value = props.createdByList

    const selectedUser = ref(null)

    const selectedPhone = ref(null)

    const { route, router } = useRouter();
    const gotoUrl = (routerName) => {
      console.log(routerName);
      router.push({ name: routerName, params: route.params });
    };

    const completeTask = (event) => {
      store.dispatch("app-todo/completeTask", event).then(() => {
        // eslint-disable-next-line no-use-before-define
        // fetchTasksByBoard()
        emit("load-task");
      });
    };

    const changeBoardName = (event) => {
        emit("re-fetch-task", event);
    }

    const listFilterByPhone = (event) => {
        selectedUser.value = null
        emit("filter-task-by-phone", event);
    }

    const listFilterByCreatedBy = (event) => {
        selectedPhone.value = null
        emit("filter-task-by-created-by", event);
    }

    return {
      gotoUrl,
      completeTask,
      phoneOptions,
      createdByOptions,
      selectedUser,
      selectedPhone,
      changeBoardName,
      listFilterByPhone,
      listFilterByCreatedBy,
      currentBoardName
    };
  },
  methods: {
    confirmComplete(workspace, space, folder, board) {
      this.$swal({
        // title: 'Өнөөдрийн ажлуудаа өндөрлүүлэхүү?',
        text: 'Өндөрлөх бол "Өндөрлөх" үгүй бол "Болих" дарна уу!',
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Өндөрлөх",
        cancelButtonText: "Болих",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        this.completeTask({ workspace, space, folder, board });
        // if (result.value) {
        //   this.$swal({
        //     icon: 'success',
        //     title: 'Deleted!',
        //     text: 'Your file has been deleted.',
        //     customClass: {
        //       confirmButton: 'btn btn-success',
        //     },
        //   })
        // }
      });
    },
  },
};
</script>
<style scoped>
.__add_todo {
  margin-right: 1rem !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
  